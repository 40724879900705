import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useNetworkError } from '../../hooks/error/useNetworkError';

type NetworkErrorDialog = {
  isOpen: boolean;
  startSTT: () => void;
};

/**
 * ネットワーク接続エラーダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const NetworkErrorDialog = React.memo(
  ({ isOpen, startSTT }: NetworkErrorDialog) => {
    const { clickNetworkErrorOKButton } = useNetworkError();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t(
          'translationDialog.ネットワーク接続中に問題が発生しました',
        ).toString()}
        buttons={[
          {
            label: t('translationDialog.再接続'),
            onClick: () => {
              clickNetworkErrorOKButton(startSTT);
            },
            gtmClassTag: 'dialog-23',
          },
        ]}
      >
        <DialogMessageContents>
          {t('translationDialog.ネットワークを確認の上、再接続してください。')}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
