import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  DEFAULT_INTERIM_CONTENT,
  InterimContent,
  RecognizedItem,
} from '@/constants';
import {
  AUTO_DETECT_MODE_TYPE,
  AutoDetectModeType,
} from '@/constants/language';

import { TTSApiRequest } from '../../features/api/ttsApi';
import type { RootState } from '../store';

import {
  VAD_THRESHOLD_DEFAULT_VALUE,
  VadThresholdType,
} from './browserAudioSettingSlice';

/**
 * マイク認識状態
 */
export const MIC_STATUS = {
  // 認識前
  NONE: 'none',
  // 認識成功
  SUCCESS: 'success',
  // 変更中
  CHANGING: 'changing',
  // 認識失敗
  ERROR: 'error',
};
export type MicStatus = (typeof MIC_STATUS)[keyof typeof MIC_STATUS];

/**
 * 画面共有状態
 */
export const SHARE_DISPLAY_STATUS = {
  // 共有前
  NONE: 'none',
  // 共有成功
  SUCCESS: 'success',
  // 共有成功（画面全体を共有中）
  SUCCESS_SCREEN: 'success_screen',
  // 音声データなし
  NO_AUDIO: 'no_audio',
  // 共有失敗
  ERROR: 'error',
};
export type ShareDisplayStatus =
  (typeof SHARE_DISPLAY_STATUS)[keyof typeof SHARE_DISPLAY_STATUS];

/**
 * 音声認識(収録)状況
 */
export const STT_STATUS = {
  // 何もしていない
  INACTIVE: 'inactive',
  // 準備中
  READY: 'ready',
  // Websocketを介して音声ストリームに接続中
  CONNECTING: 'connecting',
  // 停止中
  PAUSED: 'paused',
  // エラー
  ERROR: 'error',
};
export type STTStatus = (typeof STT_STATUS)[keyof typeof STT_STATUS];

/**
 * 音声合成(読み上げ)状況
 */
export const TTS_STATUS = {
  // 何もしていない
  INACTIVE: 'inactive',
  // 読み上げ中
  READING: 'reading',
  // 停止中
  PAUSED: 'paused',
};
export type TTSStatus = (typeof TTS_STATUS)[keyof typeof TTS_STATUS];

/**
 * 失敗理由
 */
export const STT_ERROR_TYPE = {
  // 無し
  NONE: 'none',
  // ライセンス期限切れ
  LICENSE_EXP: 'licenseExp',
  // 無料期間終了
  FREE_EXP: 'freeExp',
  // アクセス期限切れ
  ACCESS_EXP: 'accessExp',
  // PTID期限切れ
  PTID_EXP: 'ptidExp',
  // 利用規約が更新されている
  NEED_AGREEMENT: 'needAgreement',
  // 5分無音エラー
  SILENCE: 'silence',
  // 連続使用エラー
  CONSECUTIVELY: 'consecutively',
  // 音声信号の無音エラー
  NO_SOUND: 'noSound',
  // 同時接続数エラー
  CONNECTION_SHIFTED: 'connectionShifted',
  // 共有画面有効無効が切り替わった
  SWITCH_SHARE_BROWSABLE: 'switchShareBrowsable',
  // ネットワーク接続エラー
  NETWORK_ERROR: 'networkError',
  // その他エラー
  OTHER: 'other',
};
export type SttErrorType = (typeof STT_ERROR_TYPE)[keyof typeof STT_ERROR_TYPE];

/**
 * TTSするためのリクエスト情報
 */
export type TTSRequestInfo = {
  // 一意なID
  id: string;
  // TTS情報
  ttsRequest: TTSApiRequest;
};

/**
 * リトライ状態
 */
export const RETRY_STATUS = {
  // 無し
  NONE: 'none',
  // リトライ開始
  RETRY: 'retry',
  // リトライ中
  RETRYING: 'retrying',
};
export type RetryStatus = (typeof RETRY_STATUS)[keyof typeof RETRY_STATUS];

/**
 * State
 */
export type TranslationInfoState = {
  // ライセンストークン(ライセンストークン発行APIから返却された値)
  licenseToken: string;
  // ライセンス文字列(ライセンス確認APIから返却された値)
  licenseStr: string;
  // マイク認識状況
  micStatus: MicStatus;
  // 画面共有状況
  shareDisplayStatus: ShareDisplayStatus;
  // 音声認識(収録)状況
  sttStatus: STTStatus;
  // 音声合成(読み上げ)状況
  ttsStatus: TTSStatus;
  // リトライ状態
  retryStatus: RetryStatus;
  // 暫定テキスト
  // ゲスト画面の暫定テキスト更新用
  // （描画タイミングをrecognizedListと同一にするため）
  interimContent: InterimContent;
  // 翻訳画面の表示内容(翻訳リスト)
  recognizedList: EntityState<RecognizedItem>;
  // 失敗理由
  sttErrorType: SttErrorType;
  // 利用可能残時間（ミリ秒）
  remainingTime: number;
  // TTSリクエスト情報格納キュー
  ttsRequestQueue: EntityState<TTSRequestInfo>;
  // 言語自動判別モード
  currentAutoDetectMode: AutoDetectModeType;
  // 音声区間検出値
  currentVadThreshold: VadThresholdType;
};

export const recognizedListAdapter = createEntityAdapter<RecognizedItem>({
  selectId: (item) => item.id,
});

export const recognizedListAdapterSelectors =
  recognizedListAdapter.getSelectors(
    (state: RootState) => state.translationInfo.recognizedList,
  );

export const ttsRequestQueueAdapter = createEntityAdapter<TTSRequestInfo>({
  selectId: (item) => item.id,
});

export const ttsRequestQueueAdapterSelectors =
  ttsRequestQueueAdapter.getSelectors(
    (state: RootState) => state.translationInfo.ttsRequestQueue,
  );

/**
 * 初期State
 */
const initialState: TranslationInfoState = {
  licenseToken: '',
  licenseStr: '',
  micStatus: MIC_STATUS.NONE,
  shareDisplayStatus: SHARE_DISPLAY_STATUS.NONE,
  sttStatus: STT_STATUS.INACTIVE,
  ttsStatus: TTS_STATUS.INACTIVE,
  retryStatus: RETRY_STATUS.NONE,
  interimContent: DEFAULT_INTERIM_CONTENT,
  recognizedList: recognizedListAdapter.getInitialState(),
  sttErrorType: STT_ERROR_TYPE.NONE,
  remainingTime: 0,
  ttsRequestQueue: ttsRequestQueueAdapter.getInitialState(),
  currentAutoDetectMode: AUTO_DETECT_MODE_TYPE.INTERACTIVE,
  currentVadThreshold: VAD_THRESHOLD_DEFAULT_VALUE,
};

/**
 * 翻訳を行うにあたっての情報を一元管理 Slice
 */
export const translationInfoSlice = createSlice({
  name: 'translationInfo',
  initialState,
  reducers: {
    // ライセンストークンを更新
    setLicenseToken: (state, action: PayloadAction<string>) => {
      state.licenseToken = action.payload;
    },
    // ライセンス文字列を更新
    setLicenseStr: (state, action: PayloadAction<string>) => {
      state.licenseStr = action.payload;
    },
    // マイク認識状況を更新
    setMicStatus: (state, action: PayloadAction<MicStatus>) => {
      state.micStatus = action.payload;
    },
    // 画面共有状況を更新
    setShareDisplayStatus: (
      state,
      action: PayloadAction<ShareDisplayStatus>,
    ) => {
      state.shareDisplayStatus = action.payload;
    },
    // 音声認識(収録)状況を更新
    setSttStatus: (state, action: PayloadAction<STTStatus>) => {
      state.sttStatus = action.payload;
    },
    // 音声合成(読み上げ)状況を更新
    setTtsStatus: (state, action: PayloadAction<TTSStatus>) => {
      state.ttsStatus = action.payload;
    },
    // リトライ状況を更新
    setRetryStatus: (state, action: PayloadAction<RetryStatus>) => {
      state.retryStatus = action.payload;
    },
    // 暫定テキストを更新
    setInterimContent: (state, action: PayloadAction<InterimContent>) => {
      state.interimContent = action.payload;
    },
    // 翻訳画面の表示内容(翻訳リスト)を更新
    setRecognizedList: (state, action: PayloadAction<RecognizedItem[]>) => {
      recognizedListAdapter.setAll(state.recognizedList, action.payload);

      return state;
    },
    // 翻訳画面の翻訳リストに翻訳結果を新規追加
    addNewRecognizedItem: (state, action: PayloadAction<RecognizedItem>) => {
      recognizedListAdapter.addOne(state.recognizedList, action.payload);

      return state;
    },
    // 失敗理由
    setSttErrorType: (state, action: PayloadAction<SttErrorType>) => {
      state.sttErrorType = action.payload;
    },
    // 利用可能残時間（ミリ秒）
    setRemainingTime: (state, action: PayloadAction<number>) => {
      state.remainingTime = action.payload;
    },
    // TTSリクエスト情報格納キューを更新
    setTtsRequestQueue: (state, action: PayloadAction<TTSRequestInfo[]>) => {
      ttsRequestQueueAdapter.setAll(state.ttsRequestQueue, action.payload);

      return state;
    },
    // TTSリクエスト情報格納キューにリクエスト情報を新規追加
    addNewTtsRequestQueue: (state, action: PayloadAction<TTSRequestInfo>) => {
      ttsRequestQueueAdapter.addOne(state.ttsRequestQueue, action.payload);

      return state;
    },
    // 言語自動判別モード
    setCurrentAutoDetectMode: (
      state,
      action: PayloadAction<AutoDetectModeType>,
    ) => {
      state.currentAutoDetectMode = action.payload;
    },
    // 音声区間検出値を更新
    setCurrentVadThreshold: (
      state,
      action: PayloadAction<VadThresholdType>,
    ) => {
      state.currentVadThreshold = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
