import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH, URL_PARAMS_KEY } from '@/constants';
import {
  useSerialAuthApi,
  SerialAuthState,
  SERIAL_AUTH_API_RESULT_CODE,
} from '@/features/api';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useUrlParameter } from '@/hooks/useUrlParameter';

import {
  SERIAL_STATUS,
  SerialErrorMessageMap,
  SerialForm,
  SerialStatusType,
} from '../constants';

/**
 * 本カスタムフックからの返却値
 */
export type UseSerialValue = {
  // [送信]ボタンのクリック処理
  onClickSend: (inputParam: SerialForm) => void;
  // シリアル認証API State
  serialAuthState: SerialAuthState;
  // シリアル認証エラーメッセージ
  serialErrorMassage: string;
  // シリアルのデフォルト値
  defaultSerial: string;
};

/**
 * シリアル画面 hooks
 *
 * @returns
 */
export const useSerial = (): UseSerialValue => {
  const { serialAuthState, fetchSerialAuth } = useSerialAuthApi();
  const navigate = useNavigate();
  const { findParams } = useUrlParameter();
  const { setMode, setQrHours } = useBrowserUserInfo();

  // シリアル認証状態
  const [serialAuthStatusType, setSerialAuthStatusType] =
    useState<SerialStatusType>(SERIAL_STATUS.NONE);

  // シリアル認証エラーメッセージ
  const serialErrorMassage = useMemo(
    () => SerialErrorMessageMap[serialAuthStatusType],
    [serialAuthStatusType],
  );

  /**
   * [送信]ボタンのクリック処理
   */
  const onClickSend = useCallback(
    (inputParam: SerialForm) => {
      // シリアル認証API呼び出し
      fetchSerialAuth(inputParam);
    },
    [fetchSerialAuth],
  );

  /**
   * シリアルのデフォルト値
   */
  const defaultSerial = useMemo(() => {
    // URLパラメータからシリアルを取得
    const serialCode = findParams(URL_PARAMS_KEY.SERIAL.code);

    return serialCode;
  }, [findParams]);

  /**
   * 状態：シリアル認証APIリクエスト完了
   */
  useEffect(() => {
    if (!serialAuthState.data) {
      setSerialAuthStatusType(SERIAL_STATUS.NONE);

      return;
    }

    switch (serialAuthState.data.resultCode) {
      case SERIAL_AUTH_API_RESULT_CODE.OK:
        setSerialAuthStatusType(SERIAL_STATUS.SUCCESS);

        // シリアルのモードを保存
        setMode(serialAuthState.data.mode);

        // シリアルのQRコード利用時間を保存
        setQrHours(serialAuthState.data.qrHours);

        // [ホーム画面]に遷移
        navigate(PAGE_ROUTE_PATH.HOME);

        return;
      default:
        setSerialAuthStatusType(SERIAL_STATUS.ERROR);
    }
  }, [navigate, serialAuthState, setMode, setQrHours]);

  return {
    onClickSend,
    serialAuthState,
    serialErrorMassage,
    defaultSerial,
  };
};
