import React from 'react';

import { AUTO_DETECT_MODE_TYPE } from '@/constants/language';
import useOpen from '@/hooks/useOpen';

import { useLanguageInfo } from '../hooks/useLanguageInfo';

import styles from './LanguageAreaContainer.module.scss';
import { LanguageSelectDialog } from './LanguageSelectDialog';

/**
 * 言語選択エリア コンポーネント
 */
export const LanguageAreaContainer = React.memo(() => {
  const { destlangLabelName, srclangLabelName, autoDetectMode } =
    useLanguageInfo();
  const { isOpen, onOpen, onClose } = useOpen();

  return (
    <>
      <div className={styles['language-area-container']}>
        <button onClick={onOpen} className={styles['language-area-button']}>
          <span className={styles['language-text']} title={srclangLabelName}>
            {srclangLabelName}
          </span>
          <span
            className={
              autoDetectMode === AUTO_DETECT_MODE_TYPE.INTERACTIVE
                ? styles['arrow-double']
                : styles.arrow
            }
          />
          <span className={styles['language-text']} title={destlangLabelName}>
            {destlangLabelName}
          </span>
        </button>
      </div>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && (
        <LanguageSelectDialog
          isOpen={isOpen}
          isHiddenCloseButton={false}
          onClose={onClose}
        />
      )}
    </>
  );
});
