// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DIALOG_BUTTON_TYPE,
  DialogButton,
  DialogMessageContents,
  ModalDialog,
  SpinnerOverlayView,
} from '@/components/Elements';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';

import { useShareStartDialog } from '../hooks/useShareStartDialog';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * 共有開始確認ダイアログ コンポーネント
 */
export const ShareStartDialog = React.memo(({ isOpen, onClose }: Props) => {
  const { clickStartButton, isShowProgress } = useShareStartDialog({
    onCloseShareStartDialog: onClose,
  });
  const { t } = useTranslation();
  const { isBusinessMode, qrHours } = useBrowserUserInfo();

  const shareStartDialogButtons: DialogButton[] = [
    {
      label: t('shareStartDialog.キャンセル'),
      onClick: onClose,
      type: DIALOG_BUTTON_TYPE.CHANCEL,
      gtmClassTag: 'dialog-02',
    },

    {
      label: t('shareStartDialog.開始'),
      onClick: () => clickStartButton(),
      type: DIALOG_BUTTON_TYPE.INFO,
      gtmClassTag: 'dialog-01',
    },
  ];

  return (
    <>
      <SpinnerOverlayView isOpen={isShowProgress} />

      {/* 共有開始確認ダイアログ */}
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton
        title={t('shareStartDialog.通訳の共有を開始しますか？').toString()}
        buttons={shareStartDialogButtons}
      >
        <DialogMessageContents>
          {/* ビジネスモードの場合のみ表示 */}
          {isBusinessMode &&
            qrHours > 0 &&
            t('shareStartDialog.{num}時間後に自動的に終了します', {
              num: qrHours,
            })}
        </DialogMessageContents>
      </ModalDialog>
    </>
  );
});
