import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  browserUserInfoSlice,
  BrowserUserInfoState,
  SERIAL_MODE,
  SerialMode,
} from '@/states/slices/browserUserInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * ログインユーザ情報をローカルストレージに保存 hooks
 *
 * @returns
 */
export const useBrowserUserInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setMode, setQrHours, clearUserInfo } = browserUserInfoSlice.actions;

  const { mode, qrHours } = useSelector<RootState, BrowserUserInfoState>(
    (state) => state.browserUserInfo,
  );

  /**
   * カンファレンス用シリアルでログイン中か
   *
   * @returns true=カンファレンス用シリアルでログイン / false=その他でログイン
   */
  const isConferenceMode = useMemo(
    () => mode === SERIAL_MODE.CONFERENCE,
    [mode],
  );

  /**
   * forスクール用シリアルでログイン中か
   *
   *@returns true=forスクール用シリアルでログイン / false=その他でログイン
   */
  const isSchoolMode = useMemo(() => mode === SERIAL_MODE.SCHOOL, [mode]);

  /**
   * forツアー用シリアルでログイン中か
   *
   *@returns true=forツアー用シリアルでログイン / false=その他でログイン
   */
  const isTourMode = useMemo(() => mode === SERIAL_MODE.TOUR, [mode]);

  /**
   * toBusiness用シリアルでログイン中か
   *
   * @returns true=toBusiness用シリアルでログイン / false=その他でログイン
   */
  const isBusinessMode = useMemo(() => mode === SERIAL_MODE.BUSINESS, [mode]);

  /**
   * 共有が可能なモードでログイン中か
   *
   *@returns true=共有可能 / false=共有負荷
   */
  const isShareable = useMemo(
    () => isConferenceMode || isSchoolMode || isTourMode || isBusinessMode,
    [isConferenceMode, isSchoolMode, isTourMode, isBusinessMode],
  );

  /**
   * モードを更新
   */
  const changeMode = useCallback(
    (value: SerialMode) => {
      dispatch(setMode(value));
    },
    [dispatch, setMode],
  );

  /**
   * QRコード利用時間を更新
   */
  const changeQrHours = useCallback(
    (value: number) => {
      dispatch(setQrHours(value));
    },
    [dispatch, setQrHours],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearUserInfo());
  }, [dispatch, clearUserInfo]);

  return {
    // シリアルのモード
    mode,
    // QRコード利用時間
    qrHours,
    // カンファレンス用シリアルでログイン中か否か
    isConferenceMode,
    // forスクール用のシリアルか否か
    isSchoolMode,
    // forツアー用のシリアルか否か
    isTourMode,
    // toBusiness用のシリアルか否か
    isBusinessMode,
    // 共有可能か否か
    isShareable,
    // モードを更新
    setMode: changeMode,
    // QRコード利用時間を更新
    setQrHours: changeQrHours,
    // 全てのStateをリセット
    resetState,
  };
};
