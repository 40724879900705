import React from 'react';
import { useTranslation } from 'react-i18next';

import spatialAudio from '@/assets/spatial_audio.svg';
import { useTtsButton } from '@/features/texttospeech';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import {
  SHARE_DISPLAY_STATUS,
  TTS_STATUS,
} from '@/states/slices/translationInfoSlice';

import { useMenuValidator } from '../../hooks/useMenuValidator';
import styles from '../Header.module.scss';

type Props = {
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > 通訳音声を聞く/通訳音声を止めるメニュー
 */
export const TtsMenuContainer = React.memo<Props>(({ onCloseMenu }: Props) => {
  const { ttsStatus, shareDisplayStatus } = useTranslationInfo();
  const { clickTtsButton } = useTtsButton();
  const { isTranslationMenuDisabled, isTtsMenuDisabled } = useMenuValidator();
  const { t } = useTranslation();

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          onCloseMenu();
          clickTtsButton();
        }}
        disabled={
          isTranslationMenuDisabled ||
          shareDisplayStatus === SHARE_DISPLAY_STATUS.SUCCESS_SCREEN ||
          isTtsMenuDisabled
        }
        className="dts-transplay"
      >
        <div>
          <div className={styles['tts-menu']}>
            <img src={spatialAudio} alt="spatialAudio" />
            <div>
              {ttsStatus === TTS_STATUS.READING
                ? t('mainMenu.通訳音声を止める')
                : t('mainMenu.通訳音声を聞く')}
            </div>
          </div>

          {isTtsMenuDisabled ? (
            <div className={styles['tts-disabled-message']}>
              {t('mainMenu.音声読み上げが非対応の言語です。')}
            </div>
          ) : (
            ''
          )}
        </div>
      </button>
    </div>
  );
});
