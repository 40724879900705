import { useCallback } from 'react';

import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { STT_ERROR_TYPE } from '@/states/slices/translationInfoSlice';

/**
 * ネットワーク接続エラー カスタムフック
 */
export const useNetworkError = () => {
  const { setSttErrorType } = useTranslationInfo();

  /**
   * ネットワーク接続エラーダイアログ用OKボタンのクリック処理
   */
  const clickNetworkErrorOKButton = useCallback(
    (clickSttButton: () => void) => {
      // エラーダイアログを閉じる
      setSttErrorType(STT_ERROR_TYPE.NONE);
      // [STT]ボタンをクリックしてWebsocket再接続
      clickSttButton();
    },
    [setSttErrorType],
  );

  return {
    clickNetworkErrorOKButton,
  };
};
