// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import arrowDown from '@/assets/arrow_down.svg';
import arrowDownDouble from '@/assets/arrow_down_double.svg';
import {
  Button,
  ModalDialog,
  PullDownMenu,
  OptGroupPullDownMenu,
  ToggleButton,
} from '@/components/Elements';

import { useLanguageInfo } from '../hooks/useLanguageInfo';
import { useLanguageSelectDialog } from '../hooks/useLanguageSelectDialog';

import styles from './LanguageSelectDialog.module.scss';

/**
 * プロパティ
 */
type LanguageSelectDialog = {
  // 言語選択ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログの閉じるボタンを非表示にするか否か(true=非表示)
  isHiddenCloseButton: boolean;
  // 言語選択ダイアログを閉じる
  onClose: () => void;
  // 選択された言語をローカルストレージに即時保存するか(デフォルト=false)
  isSaveOnSelectLang?: boolean;
};

/**
 * 言語選択ダイアログ コンポーネント
 */
export const LanguageSelectDialog = React.memo(
  ({
    isOpen,
    isHiddenCloseButton,
    onClose,
    isSaveOnSelectLang = false,
  }: LanguageSelectDialog) => {
    const { sttLangList, ttsLangList } = useLanguageInfo();
    const {
      selectedSrclangValue,
      selectedDestlangValue,
      onChangeSrclang,
      onChangeDestlang,
      onClickOKButton,
      isInteractiveModeSelectedDestlang,
      isInteractive,
      onChangeIsInteractive,
    } = useLanguageSelectDialog({ isSaveOnSelectLang });
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={isHiddenCloseButton}
        onClickClose={onClose}
        title={t('languageSelectDialog.言語を選ぶ').toString()}
      >
        <div className={styles['language-select-container']}>
          <div className={styles['language-select-item']}>
            <div className={styles['select-item']}>
              <div className={styles['lang-list']}>
                <span className={styles['lang-list-select-span']}>
                  <PullDownMenu
                    name="sttLanguage"
                    optionItems={sttLangList}
                    selectedValue={selectedSrclangValue}
                    disabled={sttLangList.length < 1}
                    onChange={onChangeSrclang}
                    gtmClassTag="dts-sourcelang"
                  />
                </span>
              </div>
              <div className={styles.arrow}>
                {!isInteractiveModeSelectedDestlang || !isInteractive ? (
                  <img src={arrowDown} alt="arrow-down" />
                ) : (
                  <img src={arrowDownDouble} alt="arrow-down-double" />
                )}
              </div>
              <div className={styles['lang-list']}>
                <span className={styles['lang-list-select-span']}>
                  <OptGroupPullDownMenu
                    name="tttLanguage"
                    optionItems={ttsLangList}
                    selectedValue={selectedDestlangValue}
                    disabled={ttsLangList.length < 1}
                    onChange={onChangeDestlang}
                    gtmClassTag="dts-translang"
                  />
                </span>
              </div>
            </div>
            <div className={styles['interactive-check-wrapper']}>
              <div>
                <ToggleButton
                  disabled={!isInteractiveModeSelectedDestlang}
                  onChange={() => onChangeIsInteractive(!isInteractive)}
                  checked={isInteractive}
                />
              </div>
              <div
                className={`${
                  !isInteractiveModeSelectedDestlang &&
                  styles['interactive-check-label-disabled']
                }`}
              >
                {t('languageSelectDialog.双方向に通訳')}
              </div>
            </div>
            <div className={styles['interactive-caution']}>
              {isInteractive &&
                t('languageSelectDialog.認識精度が下がる場合があります')}
            </div>
            <Button
              type="button"
              title="OK"
              onClick={() => {
                onClickOKButton();
                onClose();
              }}
            />
          </div>
        </div>
      </ModalDialog>
    );
  },
);
