import { useCallback, useEffect, useMemo } from 'react';

import { LangData } from '@/constants/language';
import { DEFAULT_GUEST_DEST_LANG } from '@/constants/share';
import { VOICE_TYPE, getGuestLangList } from '@/features/api';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';
import { useLanguageList } from '@/hooks/useLanguageList';
import {
  BrowserLangData,
  convertPullDownMenuItem,
  findBrowserLang,
} from '@/utils/language';

/**
 * ゲスト画面用翻訳言語情報 hooks
 */
export const useGuestLanguageInfo = () => {
  const { uiLang, destlang } = useGuestDisplay();
  const { destLangList, setDestLangList } = useLanguageList();
  const { mode } = useGuestDisplay();

  /**
   * TTS言語一覧取得
   */
  const fetchTtsLangList = useCallback(() => {
    // 遅延対応用に一時的に対象言語を絞る
    getGuestLangList({
      voiceType: VOICE_TYPE.TTS,
      uiLanguage: uiLang,
      mode,
    }).then((response) => {
      setDestLangList(response);
    });
  }, [mode, setDestLangList, uiLang]);

  /**
   * UI言語が変更された時の処理
   */
  useEffect(() => {
    // UI言語が変更された時、再取得して表示言語を切り替える
    fetchTtsLangList();
  }, [fetchTtsLangList]);

  /**
   * ゲスト画面用の翻訳先言語リストに対象の言語が含まれるかどうか
   *
   * @param targetLang 対象の翻訳先言語
   * @returns true:含まれている、false:含まれていない
   */
  const isIncludedDestlang = useCallback(
    (targetLang: string): boolean => {
      if (!destLangList || destLangList.length <= 0) {
        return false;
      }

      return destLangList.some(
        (langData: LangData) => langData[targetLang] !== undefined,
      );
    },
    [destLangList],
  );

  /**
   * 翻訳先言語
   *
   * @returns
   */
  const currentDestlang = useMemo((): string => {
    if (destlang) {
      return destlang;
    }

    if (!destLangList || destLangList.length <= 0) {
      return '';
    }

    // ブラウザ言語を取得
    const browserLangData: BrowserLangData = findBrowserLang();
    if (!browserLangData.browserLang) {
      return DEFAULT_GUEST_DEST_LANG;
    }

    // ゲスト画面用翻訳先言語リストに含まれている場合はその言語を返す
    if (isIncludedDestlang(browserLangData.browserLang)) {
      return browserLangData.browserLang;
    }

    // 'en-US'などの表記になっている場合があるので'-'前の文字列で比較
    if (isIncludedDestlang(browserLangData.preBrowserLang)) {
      return browserLangData.preBrowserLang;
    }

    return DEFAULT_GUEST_DEST_LANG;
  }, [destLangList, destlang, isIncludedDestlang]);

  return {
    // 翻訳先言語
    currentDestlang,
    // 翻訳先言語(プルダウンメニュー用)
    ttsLangPullDownList: convertPullDownMenuItem(destLangList),
  };
};
