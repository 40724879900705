import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/lib/persistStore';

import { MainLayout, TransitionWrapperContainer } from '@/components/Layout';
import i18n from '@/config/i18n';
import { PAGE_ROUTE_PATH, URL_PARAMS_KEY } from '@/constants';
import { Agreement } from '@/features/agreement';
import {
  NotFound,
  AuthError,
  CommonErrorDialogContainer,
} from '@/features/error';
import { ShareGuestControl } from '@/features/guest';
import { Home } from '@/features/home';
import { SelectUsage } from '@/features/selectusage';
import { Serial } from '@/features/serial';
import { SignUp } from '@/features/signup';
import { ToastContainer } from '@/features/toast';
import { TranslationMic, TranslationSpeaker } from '@/features/translation';
import { Welcome } from '@/features/welcome';
import { useLiveInterpreterSetting } from '@/hooks/useLiveInterpreterSetting';
import store from '@/states/store';

// ReduxフレームワークのStore利用準備
const persistor = persistStore(store);

const App = () => {
  // UI言語を元に表示メッセージ言語切り替え
  const { uiLanguage } = useLiveInterpreterSetting();
  i18n.changeLanguage(uiLanguage);

  return (
    <>
      <Helmet>
        <title>{t('title.ポケトーク')}</title>
      </Helmet>
      <MainLayout>
        <Outlet /> {/* ここの部分のみでコンポーネントが置き換わる */}
      </MainLayout>
    </>
  );
};

/**
 * 本システムで表示する画面定義
 *
 * @returns
 */
export const AppRoutes = () => (
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        {/* トーストを表示(rootと同じ階層に表示することで画面遷移しても消えなくなる) */}
        <ToastContainer />
        {/* 共有エラーダイアログを表示(rootと同じ階層に表示する。modalで表示なのでダイアログ表示中は画面遷移不可) */}
        <CommonErrorDialogContainer />
        <Routes>
          <Route element={<App />}>
            {/* ドメイン直打ち */}
            <Route
              path="/"
              element={
                // 利用方法選択画面にリダイレクトさせたいのでラッパー挟む
                <TransitionWrapperContainer>
                  <SelectUsage />
                </TransitionWrapperContainer>
              }
            />
            {/* ようこそ画面 */}
            <Route path={PAGE_ROUTE_PATH.WELCOME} element={<Welcome />} />
            {/* ようこそ画面(サインアップ用) */}
            <Route path={PAGE_ROUTE_PATH.SIGNUP} element={<SignUp />} />
            {/* シリアル入力画面 */}
            <Route
              path={PAGE_ROUTE_PATH.SERIAL}
              element={
                <TransitionWrapperContainer>
                  <Serial />
                </TransitionWrapperContainer>
              }
            />
            {/* ホーム画面 */}
            <Route
              path={PAGE_ROUTE_PATH.HOME}
              element={
                <TransitionWrapperContainer>
                  <Home />
                </TransitionWrapperContainer>
              }
            />
            {/* 翻訳画面(マイク翻訳モード) */}
            <Route
              path={PAGE_ROUTE_PATH.TRANSLATION.MIC}
              element={
                <TransitionWrapperContainer>
                  <TranslationMic />
                </TransitionWrapperContainer>
              }
            />
            {/* 翻訳画面(スピーカー翻訳モード) */}
            <Route
              path={PAGE_ROUTE_PATH.TRANSLATION.SPEAKER}
              element={
                <TransitionWrapperContainer>
                  <TranslationSpeaker />
                </TransitionWrapperContainer>
              }
            />
            {/* 利用規約画面 */}
            <Route
              path={PAGE_ROUTE_PATH.AGREEMENT}
              element={
                <TransitionWrapperContainer>
                  <Agreement />
                </TransitionWrapperContainer>
              }
            />
            {/* 利用方法選択画面 */}
            <Route
              path={PAGE_ROUTE_PATH.SELECT_USAGE}
              element={
                <TransitionWrapperContainer>
                  <SelectUsage />
                </TransitionWrapperContainer>
              }
            />
            {/* 共有機能用ゲスト画面 */}
            <Route
              path={`${PAGE_ROUTE_PATH.GUEST}/:${URL_PARAMS_KEY.GUEST.urlKey}`}
              element={<ShareGuestControl />}
            />
            {/* 認証エラー画面 */}
            <Route path={PAGE_ROUTE_PATH.AUTH_ERROR} element={<AuthError />} />
            {/* 404エラー画面 */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
