import { useTranslation } from 'react-i18next';

import {
  Button,
  ALIGN_TYPE,
  FONT_VARIANT_TYPE,
  TextBlock,
  LinkText,
  SpinnerOverlayView,
} from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { API_STATUS, AGREEMENT_LINK_URL } from '@/constants';
import { AccessExpiredDialog } from '@/features/expired';

import { useAgreement } from '../hooks/useAgreement';

import styles from './Agreement.module.scss';

/**
 * 利用規約画面 子コンポーネント
 *
 * @returns
 */
const AgreementView = () => {
  // hooks
  const {
    onClickAgreement,
    onClickDisagreement,
    agreementUpdateState,
    logoutStatus,
    agreementErrorMassage,
    onChangeIsAgreement,
    isAgreement,
    logoutRedirectStatus,
    shouldOpenAccessExpDialog,
  } = useAgreement();
  const { t } = useTranslation();

  return (
    <div className={styles.agreement}>
      <SpinnerOverlayView
        isOpen={
          agreementUpdateState.status === API_STATUS.LOADING ||
          logoutStatus === API_STATUS.LOADING ||
          logoutRedirectStatus === API_STATUS.LOADING
        }
      />
      <div className={styles['agreement-text-container']}>
        <div className={styles['agreement-title']}>
          <TextBlock text={t('agreement.利用規約')} align={ALIGN_TYPE.center} />
        </div>
        <div className={styles['agreement-text']}>
          <TextBlock
            text={t(
              'agreement.下記の利用規約をご確認いただき、同意をした上で本サービスをご利用ください。',
            )}
            align={ALIGN_TYPE.center}
          />
        </div>
      </div>

      <div className={styles['agreement-body']}>
        <div className={styles['term-of-service']}>
          <LinkText
            title={t('agreement.利用規約')}
            href={AGREEMENT_LINK_URL}
            isNewTab
            isShowOpenIcon
          />
        </div>
        <div className={styles['agreement-checkbox']}>
          <input
            id="agreement-checkbox"
            type="checkbox"
            defaultChecked={false}
            onChange={() => onChangeIsAgreement(!isAgreement)}
          />
          <label htmlFor="agreement-checkbox">
            {t('agreement.利用規約に同意する')}
          </label>
        </div>

        <div className={styles['agree-btn']}>
          <Button
            title={t('agreement.利用を開始する')}
            type="button"
            disabled={!isAgreement}
            onClick={onClickAgreement}
          />
        </div>

        <div className={styles['disagree-btn']}>
          <Button
            title={t('agreement.同意しない')}
            type="button"
            disabled={agreementUpdateState.status === API_STATUS.LOADING}
            onClick={onClickDisagreement}
          />
        </div>
        {(agreementUpdateState.status === API_STATUS.FAILED ||
          logoutStatus === API_STATUS.FAILED) &&
          !shouldOpenAccessExpDialog && (
            <div className={styles['agreement-form-error']}>
              <TextBlock
                text={t(`agreement.${agreementErrorMassage}`)}
                align={ALIGN_TYPE.center}
                fontVariant={FONT_VARIANT_TYPE.alert}
              />
            </div>
          )}
      </div>
      {/* アクセス期限切れダイアログ */}
      <AccessExpiredDialog isOpen={shouldOpenAccessExpDialog} />
    </div>
  );
};

/**
 * 利用規約画面
 *
 * @returns
 */
export const Agreement = () => (
  <ContentLayout>
    <AgreementView />
  </ContentLayout>
);
