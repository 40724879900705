import { Buffer } from 'buffer';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import {
  DEFAULT_INTERIM_CONTENT,
  InterimContent,
  PAGE_ROUTE_PATH,
  RecognizedItem,
} from '@/constants';
import { STREAM_API_RESULT_CODE, StreamApiResponse } from '@/features/api';
import { useHistoryDownload } from '@/features/history';
import { useVoiceInput, useSystemAudio } from '@/features/recognition';
import { useTextToSpeech } from '@/features/texttospeech';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useToastInfo } from '@/hooks/useToastInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { useWakeLock } from '@/hooks/useWakeLock';
import {
  RETRY_STATUS,
  SHARE_DISPLAY_STATUS,
  STTStatus,
  STT_ERROR_TYPE,
  STT_STATUS,
} from '@/states/slices/translationInfoSlice';
import { convertDateToYyyyMMddTHhmmssUTC, currentDateUTC } from '@/utils/date';
import { canDisplayTranslationText } from '@/utils/translation';

import {
  ContinuousTimeOverFunction,
  useContinuousTimer,
} from '../timer/useContinuousTimer';
import { useNoSoundTimer } from '../timer/useNoSoundTimer';
import {
  SilenceTimeOverFunction,
  useSilenceTimer,
} from '../timer/useSilenceTimer';
import { isNoSound } from '../utils/soundChecker';

window.Buffer = Buffer;

/**
 * 本カスタムフックからの返却値
 */
export type UseTranslationSpeakerValue = {
  // [STT ON/OFF]ボタンがクリックされた際の処理
  clickSttButton: () => void;
  // リアルタイム音声認識結果
  interimContent: InterimContent;
  // [通訳を開始する]ボタンがクリックされた際の処理
  clickStartMediaCapture: () => void;
  // ホーム画面に戻る
  backHome: () => void;
  // デバイス差し替え時に画面共有の設定のやり直しを促すダイアログを表示するか否か(true=表示)
  shouldOpenDeviceChangeDialog: boolean;
  // 画面共有時の音声取得エラー ボタン型
  noAudioDialogButtons: {
    label: string;
    onClick: () => void;
    gtmClassTag: string;
  }[];
  // 画面共有エラー ボタン型
  shareDisplayErrorDialogButtons: {
    label: string;
    onClick: () => void;
    gtmClassTag: string;
  }[];
  // デバイス差し替えダイアログ ボタン型
  deviceChangeDialogButtons: {
    label: string;
    onClick: () => void;
    gtmClassTag: string;
  }[];
};

/**
 * 翻訳画面(スピーカー翻訳モード) hooks
 *
 * @returns
 */
export const useTranslationSpeaker = (): UseTranslationSpeakerValue => {
  // リアルタイム音声認識結果
  const [interimContent, setInterimContent] = useState<InterimContent>(
    DEFAULT_INTERIM_CONTENT,
  );

  const {
    licenseToken,
    sttStatus,
    addNewRecognizedItem,
    recognizedList,
    setSttErrorType,
    retryStatus,
    shareDisplayStatus,
  } = useTranslationInfo();
  const { requestStartShare, startRecording, stopRecording, stopShare } =
    useSystemAudio();
  const { addTtsList } = useTextToSpeech();
  const { srclang, destlang } = useBrowserLanguage();
  const navigate = useNavigate();
  const { addNewRecognizedItemToHistory } = useHistoryDownload();
  const { addToastMessage } = useToastInfo();
  const { t } = useTranslation();
  const { addWakeLock, removeWakeLock } = useWakeLock();
  const { isSchoolMode, isBusinessMode, isShareable } = useBrowserUserInfo();
  // デバイスが着脱されたか否か(true=着脱された)
  const [isChangeDevice, setIsChangeDevice] = useState<boolean>(false);

  /**
   * 5分無音監視 hooks
   */
  const { reObserveSilence, disconnectSilence } = useSilenceTimer();
  /**
   * 5分無音が続いた際に実行したい関数Ref
   */
  const silenceTimeOverRef = useRef<SilenceTimeOverFunction>();
  /**
   * 連続使用監視 hooks
   */
  const { reObserveContinuous, disconnectContinuous } = useContinuousTimer();
  /**
   * 5分無音が続いた際に実行したい関数Ref
   */
  const continuousTimeOverRef = useRef<ContinuousTimeOverFunction>();
  /**
   * 音声信号の無音監視タイマー hooks
   */
  const { observeNoSound, disconnectNoSound } = useNoSoundTimer();

  /**
   * 翻訳一覧の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const recognizedListRef = useRef<RecognizedItem[]>(recognizedList);
  useEffect(() => {
    recognizedListRef.current = recognizedList;
  }, [recognizedList]);

  /**
   * ライセンストークンの変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const licenseTokenRef = useRef<string>(licenseToken);
  useEffect(() => {
    licenseTokenRef.current = licenseToken;
  }, [licenseToken]);

  /**
   * 翻訳元言語の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const srclangRef = useRef<string>(srclang);
  useEffect(() => {
    srclangRef.current = srclang;
  }, [srclang]);

  /**
   * 翻訳先言語の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const destlangRef = useRef<string>(destlang);
  useEffect(() => {
    destlangRef.current = destlang;
  }, [destlang]);

  /**
   * STT状態を監視してuseRefに格納
   *
   * イベントリスナー内のuseStateやReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const sttStatusRef = useRef<STTStatus>(sttStatus);
  useEffect(() => {
    sttStatusRef.current = sttStatus;
  }, [sttStatus]);

  /**
   * 画面共有状況を監視してuseRefに格納
   *
   * イベントリスナー内のuseStateやReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const shareDisplayStatusRef = useRef<STTStatus>(shareDisplayStatus);
  useEffect(() => {
    shareDisplayStatusRef.current = shareDisplayStatus;
  }, [shareDisplayStatus]);

  /**
   * 暫定TTTテキストの変更を監視してuseRefに格納
   *
   * イベントリスナー内の値はキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const interimTTTRef = useRef<string>('');

  /**
   * 音声ストリームAPIから受け取った音声認識結果
   */
  const onRecognitionResult = useCallback(
    (response: StreamApiResponse) => {
      // 5分無音監視を再登録
      reObserveSilence(silenceTimeOverRef.current);

      if (response.resultCode !== STREAM_API_RESULT_CODE.OK) {
        // 音声認識に失敗している場合は何もしない
        return;
      }
      if (
        !canDisplayTranslationText(
          srclangRef.current,
          destlangRef.current,
          response.srclang,
          response.destlang,
        )
      ) {
        // 通訳画面で選択した翻訳先言語以外の結果は表示しない
        return;
      }
      // 反転フラグ
      const isReversed =
        response.srclang !== srclangRef.current &&
        response.srclang === destlangRef.current;
      if (response.isFinal) {
        // 確定後はリアルタイム結果を消去
        setInterimContent(DEFAULT_INTERIM_CONTENT);
        interimTTTRef.current = '';
        // 通訳データ
        const recognizedItem: RecognizedItem = {
          id: recognizedListRef.current.length + 1,
          value: {
            stt: response.stt,
            ttt: response.ttt,
            srclang: response.srclang,
            destlang: response.destlang,
            date: convertDateToYyyyMMddTHhmmssUTC(currentDateUTC()),
            isReversed,
          },
        };
        // stt, ttt 結果をReduxに追加
        addNewRecognizedItem(recognizedItem);
        // stt, ttt 結果をローカルストレージの履歴に追加
        addNewRecognizedItemToHistory(recognizedItem);
        // 音声読み上げリストに追加
        addTtsList(
          isReversed ? response.stt : response.ttt,
          destlangRef.current,
        );
      } else {
        // 空ではない暫定TTTを保持(空のTTTが返ってきたら前回の暫定TTTを表示するため)
        if (response.ttt) {
          interimTTTRef.current = response.ttt;
        }
        // 音声認識中テキスト更新
        setInterimContent({
          stt: response.stt,
          ttt: interimTTTRef.current,
          srclang: response.srclang,
          destlang: response.destlang,
          isReversed,
        });
      }
    },
    [
      reObserveSilence,
      addNewRecognizedItem,
      addNewRecognizedItemToHistory,
      addTtsList,
    ],
  );

  /**
   * 音声入力 hooks
   */
  const { requestStartStream, closeStream, sendAudio } = useVoiceInput({
    onResult: onRecognitionResult,
    startAudioContext: startRecording,
    stopAudioContext: stopRecording,
  });

  /**
   * 音声信号の無音が既定時間経過した際に実行する処理
   */
  const noSoundTimeOver = useCallback(() => {
    // 音声信号無音エラーダイアログを表示
    setSttErrorType(STT_ERROR_TYPE.NO_SOUND);
  }, [setSttErrorType]);

  /**
   * 音声信号の無音が続いているかどうかを監視する
   */
  const operateNoSoundTimer = useCallback(
    (data: ArrayBufferLike) => {
      if (sttStatusRef.current !== STT_STATUS.CONNECTING) {
        return;
      }

      if (isNoSound(data)) {
        observeNoSound(noSoundTimeOver);
      } else {
        disconnectNoSound();
      }
    },
    [disconnectNoSound, noSoundTimeOver, observeNoSound],
  );

  /**
   * 音声キャプチャ結果を音声ストリームAPIに渡す
   */
  const onShareDisplayResult = useCallback(
    (data: any) => {
      // 画面全体共有時のみ音声信号の無音を監視する
      if (
        shareDisplayStatusRef.current === SHARE_DISPLAY_STATUS.SUCCESS_SCREEN
      ) {
        operateNoSoundTimer(data);
      }
      sendAudio(data);
    },
    [operateNoSoundTimer, sendAudio],
  );

  /**
   * [ホーム画面]に戻る
   */
  const backHome = useCallback(() => {
    navigate(PAGE_ROUTE_PATH.HOME);
  }, [navigate]);

  /**
   * 音声認識停止処理
   */
  const stopStt = useCallback(() => {
    closeStream();
    // リアルタイム結果を消去
    setInterimContent(DEFAULT_INTERIM_CONTENT);
  }, [closeStream]);

  /**
   * [STT ON/OFF]ボタンがクリックされた際の処理
   */
  const clickSttButton = useCallback(() => {
    // 音声認識開始
    if (sttStatus === STT_STATUS.PAUSED || sttStatus === STT_STATUS.INACTIVE) {
      requestStartStream();

      return;
    }

    // 音声認識停止
    if (sttStatus === STT_STATUS.CONNECTING) {
      stopStt();
    }
  }, [requestStartStream, stopStt, sttStatus]);

  /**
   * リトライが開始されたらリアルタイムSTT結果を消去
   */
  useEffect(() => {
    if (retryStatus === RETRY_STATUS.RETRYING) {
      setInterimContent(DEFAULT_INTERIM_CONTENT);
    }
  }, [retryStatus]);

  /*
   * [通訳を開始する]ボタンがクリックされた際の処理
   */
  const clickStartMediaCapture = useCallback(() => {
    // 画面共有ダイアログを表示する
    requestStartShare(onShareDisplayResult, backHome);
  }, [backHome, onShareDisplayResult, requestStartShare]);

  /**
   * 無音が既定時間経過した際に実行
   */
  const silenceTimeOver = useCallback(() => {
    // 5分無音エラーダイアログを表示
    setSttErrorType(STT_ERROR_TYPE.SILENCE);
    // [STT停止]ボタンクリック
    clickSttButton();
  }, [clickSttButton, setSttErrorType]);
  useEffect(() => {
    silenceTimeOverRef.current = silenceTimeOver;
  }, [silenceTimeOver]);

  /**
   * 連続使用が既定時間経過した際に実行する処理
   */
  const continuousTimeOver = useCallback(() => {
    // 連続使用エラーダイアログを表示
    setSttErrorType(STT_ERROR_TYPE.CONSECUTIVELY);
    // [STT停止]ボタンクリック
    clickSttButton();
  }, [clickSttButton, setSttErrorType]);
  useEffect(() => {
    continuousTimeOverRef.current = continuousTimeOver;
  }, [continuousTimeOver]);

  /**
   * 画面共有時の音声取得エラー ボタン型
   */
  const noAudioDialogButtons = [
    {
      label: 'OK',
      onClick: backHome,
      gtmClassTag: 'dts-error-sharescreen-audio',
    },
  ];

  /**
   * 画面共有エラー ボタン型
   */
  const shareDisplayErrorDialogButtons = [
    {
      label: 'OK',
      onClick: backHome,
      gtmClassTag: 'dts-error-sharescreen',
    },
  ];

  /**
   * デバイス差し替えダイアログ ボタン型
   */
  const deviceChangeDialogButtons = [
    {
      label: 'OK',
      onClick: backHome,
      gtmClassTag: 'dts-error-devicechange',
    },
  ];

  /**
   * Websocketへの接続状態を監視
   */
  useEffect(() => {
    // Websocketに接続中
    if (sttStatus === STT_STATUS.CONNECTING) {
      // 5分無音監視を再登録
      reObserveSilence(silenceTimeOverRef.current);
      // 連続使用監視を再登録する
      // 条件：PTIDでログイン済の場合
      //      forスクールのシリアルでログイン済の場合
      //      toBusinessのシリアルでログイン済の場合
      // ※それ以外は監視を行わない
      if (!isShareable || isSchoolMode || isBusinessMode) {
        reObserveContinuous(continuousTimeOverRef.current);
      }

      return;
    }

    // Websocketへの接続停止
    if (sttStatus === STT_STATUS.PAUSED) {
      // 5分無音監視,90分連続使用監視を削除
      // 途中でリトライするときは必ずSTTが停止されてからREADY状態になるので停止時にのみ削除でOK
      disconnectSilence();
      disconnectContinuous();
      disconnectNoSound();
    }
  }, [
    disconnectContinuous,
    disconnectNoSound,
    disconnectSilence,
    isSchoolMode,
    isBusinessMode,
    isShareable,
    reObserveContinuous,
    reObserveSilence,
    sttStatus,
  ]);

  /**
   * 画面共有状況が変更したときの処理
   */
  useEffect(() => {
    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.SUCCESS_SCREEN) {
      addToastMessage(
        t(
          'translationSpeaker.通訳したい音声がシステム設定と同じサウンドデバイスから再生されているか確認してください。',
        ),
        TOAST_ICON_TYPE.INFO,
      );
    }
  }, [addToastMessage, shareDisplayStatus, t]);

  /**
   * 画面共有の設定のやり直しを促すダイアログを表示する
   * ※音声認識準備中にデバイスが差し変わった場合は、STTが開始されてからダイアログを表示
   */
  const shouldOpenDeviceChangeDialog =
    sttStatus !== STT_STATUS.READY && isChangeDevice;

  /**
   * 画面共有の設定のやり直しを促すダイアログを表示された時にSTT中なら停止する
   */
  useEffect(() => {
    if (shouldOpenDeviceChangeDialog) {
      stopStt();
    }
  }, [shouldOpenDeviceChangeDialog, stopStt]);

  /**
   * 画面全体で共有中の場合のみ、
   * デバイスの着脱イベントを監視
   */
  useEffect(() => {
    const changeDeviceCallbackFn = () => {
      // デバイス着脱フラグを更新
      setIsChangeDevice(true);
      // トラック(MediaStreamTrack)を明示的に停止する(停止しないと画面共有停止イベントが実行されて勝手にホーム画面に戻るため)
      stopShare();
    };

    // イベント登録
    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.SUCCESS_SCREEN) {
      navigator.mediaDevices.addEventListener(
        'devicechange',
        changeDeviceCallbackFn,
      );
    }

    // イベント削除
    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        changeDeviceCallbackFn,
      );
    };
  }, [shareDisplayStatus, stopShare]);

  /**
   * 画面表示(マウント時/アンマウント時)
   */
  useEffect(() => {
    // 画面暗転・ロック制御
    addWakeLock();

    return () => {
      // 画面暗転・ロック制御を解除
      removeWakeLock();
    };

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clickSttButton,
    interimContent,
    clickStartMediaCapture,
    backHome,
    shouldOpenDeviceChangeDialog,
    noAudioDialogButtons,
    shareDisplayErrorDialogButtons,
    deviceChangeDialogButtons,
  };
};
