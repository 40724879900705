// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import displaySettings from '@/assets/display_settings.svg';
import { LIST_DISPLAY_TYPE, ListDisplayType } from '@/constants';

import { useMenuValidator } from '../../header/hooks/useMenuValidator';
import { useDisplayTypeButton } from '../hooks/useDisplayTypeButton';

/**
 * プロパティ
 */
type Props = {
  // 表示メニューを閉じる処理
  onCloseMenu: () => void;
  // 現在の表示タイプ
  currentDisplayType: ListDisplayType;
  // 表示切り替え時に実行したい処理
  onChangeDisplayTypeFunc: (value: ListDisplayType) => void;
  // GTM用クラスタグ
  gtmClassTag?: string;
};

/**
 * ヘッダー > メインメニュー > 表示メニュー
 */
export const DisplayMenuContainer = React.memo<Props>(
  ({
    onCloseMenu,
    currentDisplayType,
    onChangeDisplayTypeFunc,
    gtmClassTag = '',
  }: Props) => {
    const { onClickButton } = useDisplayTypeButton({
      currentDisplayType,
      onChangeDisplayTypeFunc,
    });
    const { isTranslationMenuDisabled } = useMenuValidator();
    const { t } = useTranslation();

    return (
      <button
        type="button"
        onClick={() => {
          onCloseMenu();
          onClickButton();
        }}
        disabled={isTranslationMenuDisabled}
        className={gtmClassTag}
      >
        <img src={displaySettings} alt="displaySettings" />
        {currentDisplayType === LIST_DISPLAY_TYPE.onePhrase
          ? t('mainMenu.2言語で表示する')
          : t('mainMenu.1言語で表示する')}
      </button>
    );
  },
);
