import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useConnectionOverError } from '../../hooks/error/useConnectionOverError';

type ConnectionOverErrorDialog = {
  isOpen: boolean;
};

/**
 * 同時接続超過エラーダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const ConnectionOverErrorDialog = React.memo(
  ({ isOpen }: ConnectionOverErrorDialog) => {
    const { clickConnectionOverErrorOKButton } = useConnectionOverError();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t(
          'translationDialog.同時利用ライセンス数を超えたので停止しました',
        ).toString()}
        buttons={[
          {
            label: t('translationDialog.OK'),
            onClick: clickConnectionOverErrorOKButton,
            gtmClassTag: 'dialog-22',
          },
        ]}
      >
        <DialogMessageContents> </DialogMessageContents>
      </ModalDialog>
    );
  },
);
